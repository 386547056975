<template>
  <div @click="hideHandle" class="user-address">
    <div @click.stop="preventHandle" class="content">
      <div class="tab-header">
        <div @click="tableChange(0)" :class="[tabIndex === 0 ? 'active' : '', 'tab-item']">选择收货地址</div>
        <div @click="tableChange(1)" :class="[tabIndex === 1 ? 'active' : '', 'tab-item']">{{editId ? '修改地址' : '新增地址'}}</div>
      </div>
      <AddressAdd :editId="editId" @hideModal="hideHandle" v-if="tabIndex === 1" />
      <AddressList @editHandle="editHandle" @hideModal="hideHandle" v-if="tabIndex === 0" />
    </div>
  </div>
</template>
<script>
import AddressAdd from './add.vue'
import AddressList from './list.vue'
export default {
  components: {
    AddressAdd,
    AddressList
  },
  data () {
    return {
      tabIndex: 0,
      editId: ''
    }
  },
  methods: {
    // 修改
    editHandle (id) {
      this.tabIndex = 1
      this.editId = id
    },
    tableChange (index) {
      this.tabIndex = index
      this.editId = ''
    },
    hideHandle () {
      this.$emit('hideAddressModal')
    },
    preventHandle () {
      return false
    }
  }
}
</script>
<style lang="scss" scoped>
.user-address {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  .content {
    background-color: #ffffff;
    border-radius: 5px;
    width: auto;
    padding: 10px 20px 20px;
    .tab-header {
      border-bottom: 1px solid #e6e6e6;
      display: flex;
      margin-bottom: 20px;
      .tab-item {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 50px;
        cursor: pointer;
        &.active {
          color: #D9262C;
        }
      }
    }
  }
}
</style>
