<template>
  <div class="address-list">
    <el-table
      :data="tableData"
      stripe
      size="small"
      :header-cell-style="getRowClass"
      style="width: 100%">
      <el-table-column
        prop="address"
        width="300"
        label="地址">
        <template slot-scope="scope">
          {{scope.row.province + scope.row.city + scope.row.area + scope.row.address}}
        </template>
      </el-table-column>
      <el-table-column
        prop="consignee"
        width="100"
        label="收货人">
      </el-table-column>
      <el-table-column
        prop="contact"
        width="150"
        label="联系方式">
      </el-table-column>
      <el-table-column
        prop="postal_code"
        width="100"
        label="邮政编码">
      </el-table-column>
      <el-table-column
        prop="is_default"
        width="70"
        label="默认">
        <template slot-scope="scope">
          {{scope.row.is_default === 1 ? '是' : '否'}}
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        width="100">
        <template slot-scope="scope">
          <el-button @click="eidtHandle(scope.row)" type="text" size="small" style="color: #3385FF;">修改</el-button>
          <el-button @click="deleteHandle(scope.row)" type="text" style="color: red;" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="total > params.per_page"
      class="page-container"
      small
      layout="prev, pager, next"
      @current-change="sizeChange"
      :page-size="params.per_page"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
export default {
  data () {
    return {
      tableData: [],
      params: {
        page: 1,
        per_page: 5
      },
      total: 0
    }
  },
  created () {
    this.getAddressList()
  },
  methods: {
    // 修改
    eidtHandle (row) {
      this.$emit('editHandle', row.id)
    },
    // 分页
    sizeChange (page) {
      console.log(page)
      this.params.page = page
      this.getAddressList()
    },
    // 删除
    deleteHandle (row) {
      this.$confirm('此操作将永久删除该记录, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        console.log(row)
        await this.$http.delete(`/api/address/${row.id}`, {})
        this.getAddressList()
        this.$message({
          type: 'success',
          message: '删除成功!'
        });
        this.getAddressList()
      }).catch(() => {
        console.log('取消删除')
      })
    },
    // 获取地址列表
    async getAddressList () {
      const res = await this.$http.get('/api/address', this.params)
      this.total = res.total
      this.tableData = res.data
    },
    getRowClass ({ rowIndex }) {
      if (rowIndex === 0) {
        return { background:'RGB(238,239,246)', fontSize: '13px', fontWeight: '600', padding: '13px 0'}
      } else {
        return ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.address-list {
  padding-bottom: 20px;
  .page-container {
    display: flex;
    justify-content: center;
    margin: 20px auto 0;
  }
}

::v-deep {
  .el-table__header .el-table__cell {
    background: #ececec !important;
    color: #000000;
  }
}
</style>
