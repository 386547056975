<template>
  <div @click="hideHandle" class="bind-mobile">
    <div @click.stop="preventHandle" class="form">
      <div class="title">修改绑定手机号</div>
      <div class="form-item">
        <div class="label"><span>*</span>手机号码：</div>
        <div class="data-value">
          <input v-model="params.mobile" type="text" placeholder="请输入手机号码">
        </div>
      </div>
      <div class="form-item">
        <div class="label"><span>*</span>短信验证码：</div>
        <div class="data-value">
          <input v-model="params.code" type="text" class="code" placeholder="请输入验证码">
          <div @click="getCodeHandle" class="get-code">{{codeText}}</div>
        </div>
      </div>
      <div @click="bindHandle" class="btn save-btn">重新绑定</div>
    </div>
  </div>
</template>
<script>
import md5 from 'js-md5'
export default {
  data () {
    return {
      params: {
        mobile: '',
        code: ''
      },
      key: process.env.VUE_APP_SIGNKEY,
      codeText: '获取验证码',
      timer: null,
      num: 60
    }
  },
  beforeDestroy () {
    clearInterval(this.timer)
  },
  methods: {
    // 绑定
    async bindHandle () {
      if (!this.checktel(this.params.mobile)) {
        return false
      }
      if (!this.params.code) {
        this.$message({
          type: 'error',
          message: '验证码不能为空',
          duration: 1500
        })
        return false
      }
      await this.$http.put('/api/modify-mobile', this.params)
      await this.$store.dispatch('setUserInfo')
      this.hideHandle()
    },
    // 获取验证码
    async getCodeHandle() {
      if (this.num != 60) {
        return false
      }
      if (!this.checktel(this.params.mobile)) {
        return false
      }
      const timestamp = parseInt(new Date().getTime() / 1000).toString()
      const params = {
        mobile: this.params.mobile,
        sign: md5(md5(timestamp) + md5(this.params.mobile) + md5(this.key)),
        timestamp: Number(timestamp)
      }
      const _this = this
      this.timer = setInterval(() => {
        _this.num = _this.num - 1
        if (_this.num <= 0) {
          clearInterval(_this.timer)
          _this.codeText = '获取验证码'
          _this.num = 60
        } else {
          _this.codeText = _this.num  + 's后重试'

        }
      }, 1000)
      console.log(params)
      await this.$http.post('/common/sendsms', params)
    },
    // 手机号校验
    checktel (val){
      if (val=='') {
        this.$message.error('手机号不能为空')
        return false
      }
      let myreg = /^[1][2,3,4,5,7,6,8,9][0-9]{9}$/
      if (!myreg.test(val)) {
        this.$message.error('手机号格式不正确')
        return false
      }
      return true
    },
    preventHandle () {
      return false
    },
    hideHandle () {
      this.$emit('hideBindMobile')
    }
  }
}
</script>
<style lang="scss" scoped>
.bind-mobile {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  .form {
    background-color: #fff;
    border-radius: 5px;
    width: 450px;
    padding: 40px 30px;
    .title {
      text-align: center;
      color: #D9262C;
      font-size: 24px;
      margin-bottom: 40px;
    }
    .form-item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .label {
        min-width: 90px;
        text-align: right;
        span {
          color: red;
        }
      }
      .data-value {
        flex: 1;
        position: relative;
        input {
          width: 100%;
          line-height: 40px;
          outline: none;
          border-radius: 2px;
          border: 1px solid #e6e6e6;
          padding: 0 13px;
          &.code {
            padding-right: 100px;
          }
        }
        .get-code {
          position: absolute;
          top: 50%;
          right: 13px;
          transform: translateY(-50%);
          color: #D9262C;
          cursor: pointer;
        }
      }
    }
    .save-btn {
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      background-color: #D9262C;
      margin-top: 40px;
      height: 42px;
      cursor: pointer;
      &:hover {
        opacity: .8;
      }
    }
  }
}
</style>
