<template>
  <div @click="hideHandle" class="company-auth">
    <div @click.stop="preventHandle" class="form">
      <div class="title" style="color:#D9262C">升级企业认证用户</div>
      <div class="form-item">
        <div class="label"><span>*</span>企业名称：</div>
        <div class="form-value">
          <input :value="userInfo.company_name" readonly type="text" placeholder="请输入企业名称">
        </div>
      </div>
      <div class="form-item">
        <div class="label"><span>*</span>企业地址：</div>
        <div class="form-value">
          <input v-model="params.company_address" type="text" placeholder="请输入企业详细地址">
        </div>
      </div>
      <div class="form-item">
        <div class="label"><span>*</span>联系人：</div>
        <div class="form-value">
          <input v-model="params.contact_person" type="text" maxlength="15" placeholder="请输入联系人姓名">
        </div>
      </div>
      <div class="form-item">
        <div class="label"><span>*</span>联系电话：</div>
        <div class="form-value">
          <input v-model="params.contact_tel" maxlength="20" type="text" placeholder="请输入联系电话">
        </div>
      </div>
      <div class="form-item upload-item">
        <div class="label"><span>*</span>营业执照：</div>
        <div class="form-value up-box">
          <!-- <input :value="picName" readonly type="text" placeholder="请上传营业执照"> -->
          <div class="upload">
            <el-upload :show-file-list="false" class="upload-demo" accept=".jpg,.jpeg,.png,.JPG,.JPEG,.PBG" action
              :on-change="handleChange">
              <img v-if="params && params.business_license" :src="params.business_license" class="avatar">
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </div>
        </div>
      </div>
      <div class="tips">服务须知：审核时间为3个工作日内，如遇节假日顺延！如果疑问请咨询客服</div>
      <div @click="saveHandle" class="save-btn">提交申请</div>
      <div class="agree">
        <span @click="isAgree = !isAgree" v-if="isAgree" class="icon iconfont icon-checkbox-xuanzhong"></span>
        <span @click="isAgree = !isAgree" v-else class="icon iconfont icon-checkbox-weixuan"></span>
        我已充分阅读、理解并同意
        <span @click="showAgreement" class="agree-text">《鹏隆工品网站客户注册须知》</span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import OSS from 'ali-oss'
export default {
  data() {
    return {
      isAgree: false,
      params: {
        company_address: '',
        contact_person: '',
        contact_tel: '',
        business_license: ''
      },
      picName: ''
    }
  },
  computed: {
    ...mapState([
      'userInfo'
    ])
  },
  created() {
    if (this.userInfo && this.userInfo.company_auth && this.userInfo.company_auth.company_address) {
      this.params.company_address = this.userInfo.company_auth.company_address
    }
    if (this.userInfo && this.userInfo.company_auth && this.userInfo.company_auth.contact_person) {
      this.params.contact_person = this.userInfo.company_auth.contact_person
    }
    if (this.userInfo && this.userInfo.company_auth && this.userInfo.company_auth.contact_tel) {
      this.params.contact_tel = this.userInfo.company_auth.contact_tel
    }
    if (this.userInfo && this.userInfo.company_auth && this.userInfo.company_auth.business_license) {
      this.params.business_license = this.userInfo.company_auth.business_license
      this.picName = this.userInfo.company_auth.business_license
    }
  },
  methods: {
    async showAgreement() {
      const _this = this
      const res = await this.$http.get('/api/acticle/207', {})
      console.log(res)
      this.$alert('<div style="overflow-y: auto; height: 680px;">' + res.content + '</div>', res.name, {
        dangerouslyUseHTMLString: true,
        customClass: 'agreement-modal',
        confirmButtonText: '已同意本协议',
        callback: function (action, instance) {
          console.log(action, instance)
          if (action === 'confirm') {
            _this.isAgree = true
          }
        }
      })
    },
    async handleChange(file) {
      const _this = this
      const res = await this.$http.get('/common/asset/ststoken', {})
      const client = new OSS({
        accessKeyId: res.Credentials.AccessKeyId,
        accessKeySecret: res.Credentials.AccessKeySecret,   // 你创建的Bucket时获取的
        bucket: res.bucket,  // 你创建的Bucket名称
        region: res.region_id,   //  你所购买oss服务的区域，默认oss-cn-hangzhou
        stsToken: res.Credentials.SecurityToken,
        timeout: 60000,//超时时间 默认60S 自行设置
      })
      let result = await client.put(file.name, file.raw)
      _this.params.business_license = result.url
      _this.picName = result.name
    },
    preventHandle() {
      return false
    },
    hideHandle() {
      this.$emit('hideCompanyAuth')
    },
    async saveHandle() {
      if (!this.params.company_address) {
        this.$message({
          type: 'error',
          message: '企业名称不能为空',
          duration: 1500
        })
        return false
      }
      if (!this.params.contact_person) {
        this.$message({
          type: 'error',
          message: '联系人不能为空',
          duration: 1500
        })
        return false
      }
      if (!this.params.contact_tel) {
        this.$message({
          type: 'error',
          message: '联系电话不能为空',
          duration: 1500
        })
        return false
      }
      if (!this.params.business_license) {
        this.$message({
          type: 'error',
          message: '营业执照不能为空',
          duration: 1500
        })
        return false
      }
      if (!this.isAgree) {
        this.$message({
          type: 'error',
          message: '请阅读并同意《鹏隆工品网站客户注册须知》',
          duration: 1500
        })
        return false
      }
      await this.$http.post('/api/company-auth', this.params)
      await this.$store.dispatch('setUserInfo')
      this.hideHandle()
    }
  }
}
</script>
<style lang="scss">
.agreement-modal {
  width: 700px;
  height: 800px;
}

.el-upload--text {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;
  }
}
</style>
<style lang="scss" scoped>
.company-auth {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background-color: rgba(0, 0, 0, .6);
  display: flex;
  justify-content: center;
  align-items: center;

  .form {
    background-color: #ffffff;
    border-radius: 5px;
    padding: 40px 40px;
    // font-size: 16px;
    width: 560px;

    .title {
      text-align: center;
      color: #3385FF;
      font-size: 24px;
      margin-bottom: 40px;
    }

    .form-item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      &.upload-item {
        align-items: flex-start;
      }

      .label {
        min-width: 76px;
        text-align: right;

        span {
          color: red;
        }
      }

      .form-value {
        flex: 1;
        position: relative;

        &.up-box {
          input {
            padding-right: 65px;
          }
        }

        .upload {
          // position: absolute;
          // top: 50%;
          // transform: translateY(-50%);
          // right: 13px;
          width: 150px;
          height: 150px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px dotted #D9262C;
          cursor: pointer;
          color: #D9262C;

          .upload-demo {
            width: 100%;
            height: 100%;
          }

          .el-icon-plus {
            font-size: 20px;
            font-weight: 200;
          }
        }

        input {
          width: 100%;
          line-height: 40px;
          outline: none;
          border-radius: 2px;
          border: 1px solid #e6e6e6;
          padding: 0 13px;
        }
      }
    }

    .tips {
      color: #999999;
      text-align: center;
      font-size: 14px;
      margin-top: 30px;
    }

    .save-btn {
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      background-color: #D9262C;
      margin-top: 40px;
      height: 42px;
      cursor: pointer;

      &:hover {
        opacity: .8;
      }
    }

    .agree {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 20px;
      color: #999999;

      .iconfont {
        font-size: 18px;
        // transform: translateY(1px);
        margin-right: 2px;

        &.icon-checkbox-xuanzhong {
          color: #D9262C;
        }
      }

      span {
        cursor: pointer;
      }

      .agree-text {
        color: #D9262C;
      }
    }
  }
}

// ::v-deep {
//   .company-auth .form .form-item .form-value .upload {
//     border: #D9262C;
//     color: #D9262C;
//   }
// }
</style>
