<template>
  <div @click="hideHandle" class="reset-password">
    <div @click.stop="preventHandle" class="form">
      <div class="title">设置新密码</div>
      <div class="form-item">
        <div class="label"><span>*</span>旧密码：</div>
        <div class="data-value">
          <input v-model="params.old_password" type="password" placeholder="请输入旧密码">
        </div>
      </div>
      <div class="form-item">
        <div class="label"><span>*</span>新密码：</div>
        <div class="data-value">
          <input v-model="params.password" type="password" placeholder="请输入新密码">
        </div>
      </div>
      <div class="form-item">
        <div class="label"><span>*</span>确认密码：</div>
        <div class="data-value">
          <input v-model="params.password_confirmation" type="password" placeholder="请再次输入密码">
        </div>
      </div>
      <div @click="resetHandle" class="btn save-btn">确认重置</div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      params: {
        old_password: '',
        password: '',
        password_confirmation: ''
      }
    }
  },
  methods: {
    async resetHandle () {
      if (!this.params.old_password) {
        this.$message({
          type: 'error',
          message: '请输入原始密码',
          duration: 1500
        })
        return false
      }
      if (!this.params.password) {
        this.$message({
          type: 'error',
          message: '请输入新密码',
          duration: 1500
        })
        return false
      }
      if (!this.params.password_confirmation) {
        this.$message({
          type: 'error',
          message: '请再次输入密码',
          duration: 1500
        })
        return false
      }
      if (this.params.password_confirmation !== this.params.password) {
        this.$message({
          type: 'error',
          message: '两次输入的密码不一致',
          duration: 1500
        })
        return false
      }
      await this.$http.put('/api/modify-password', this.params)
      await this.$store.dispatch('setUserInfo', {})
      localStorage.clear()
      this.$router.push({ path: '/login' })
    },
    preventHandle () {
      return false
    },
    hideHandle () {
      this.$emit('hideResetPassword')
    }
  }
}
</script>
<style lang="scss" scoped>
.reset-password {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .6);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  .form {
    background-color: #fff;
    border-radius: 5px;
    width: 450px;
    padding: 40px 30px;
    .title {
      text-align: center;
      color: #D9262C;
      font-size: 24px;
      margin-bottom: 40px;
    }
    .form-item {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      .label {
        min-width: 78px;
        text-align: right;
        span {
          color: red;
        }
      }
      .data-value {
        flex: 1;
        input {
          width: 100%;
          line-height: 40px;
          outline: none;
          border-radius: 2px;
          border: 1px solid #e6e6e6;
          padding: 0 13px;
        }
      }
    }
    .save-btn {
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ffffff;
      background-color: #D9262C;
      margin-top: 40px;
      height: 42px;
      cursor: pointer;
      &:hover {
        opacity: .8;
      }
    }
  }
}
</style>
