import { render, staticRenderFns } from "./company.vue?vue&type=template&id=2ec95a1b&scoped=true&"
import script from "./company.vue?vue&type=script&lang=js&"
export * from "./company.vue?vue&type=script&lang=js&"
import style0 from "./company.vue?vue&type=style&index=0&id=2ec95a1b&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2ec95a1b",
  null
  
)

export default component.exports